import restClient from 'api/RestClient/RestClient';
import { resourceKeys } from 'constants/restResources/restResources';
import { TTL_ONE_YEAR } from 'constants/cache/cache';

const loadFeatureConfig = ({ refreshCache } = { refreshCache: false }) =>
  restClient.get(resourceKeys.CONFIG_SERVICE_RESOURCE, '', false, {
    // Features-Toggles are refreshed (passively) in background by a cron job in app/entry.server.js.
    // Therefore we set the cache TTL here to (basically) never expire to ensure the active/blocking request
    // for this data by framework "needs" functionality (e.g. DefaultLayout.js) is always served from cache.
    cacheInSec: TTL_ONE_YEAR,
    refreshCache,
  });

export default {
  loadFeatureConfig,
};
