import { basePaths } from 'config/config.helper';
import { getEnv } from 'config/config';

const prependUrlWithApiBase = (tail) => {
  if (__CLIENT__ && getEnv('proxyCDNRoutes') === 'true') {
    // use relative path for requests in the browser
    return tail;
  }

  // use absolute path for SSR / nodejs api requests
  return `${getEnv('api')}${tail}`;
};

export const resources = {
  get API_RESOURCE() {
    return '/api';
  },
  get BRAND_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/brand`);
  },
  get CMS_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/cms`);
  },
  get PRODUCT_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/products`);
  },
  get USER_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/users`);
  },
  get STORE_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/stores`);
  },
  get TOKEN_RESOURCE() {
    return prependUrlWithApiBase(basePaths.tokenResource);
  },
  get SC_REFRESH_TOKEN() {
    return prependUrlWithApiBase(`${basePaths.supercardUrl}/login/token`);
  },
  get FORGOTTEN_PASSWORD_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/forgottenpasswordtokens`);
  },
  get LOGOUT_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/revoketoken`);
  },
  get CONTACT_RESOURCE() {
    return prependUrlWithApiBase(`/contact`);
  },
  get NEWSLETTER_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/newsletter`);
  },
  get COMPARISON_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/productComparison`);
  },
  get RECAPTCHA_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/recaptcha`);
  },
  get RATINGS_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/reviews`);
  },
  get VERIFY_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/verify`);
  },
  get INVENTORY_MICROSERVICE_RESOURCE() {
    return `${getEnv('inventoryApiUrl')}/v1/inventory`;
  },
  get BASE_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}`);
  },
  get CONFIG_SERVICE_RESOURCE() {
    return `${getEnv('configApiUrl')}/api`;
  },
  get EMAIL_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/email`);
  },
  get RETURN_MICROSERVICE_RESOURCE() {
    return `${getEnv('returnApiUrl')}/api`;
  },
  get GOOGLE_SERVICE_RESOURCE() {
    return `${getEnv('gmapsApiUrl')}/api/geocoding/`;
  },
  get FLASHMESSAGE_RESOURCE() {
    return prependUrlWithApiBase(`${basePaths.baseSiteUrl}/flashMessages`);
  },
  get SMART_EDIT_RESOURCE() {
    return `${this.CMS_RESOURCE}/preview`;
  },
};

export const resourceKeys = Object.keys(resources).reduce(
  (keyObject, key) => Object.assign(keyObject, { [key]: key }),
  {}
);

export default {
  resources,
  resourceKeys,
};
